.bubbles {
  position: absolute;
  z-index: 6;
  width: 1920px;
  height: 100%;
  top: 0;
  left: 50%;
  margin-left: -960px;
  display: none;
}

.bubble {
  width: 100px;
  height: 100px;
  position: absolute;
  border-radius: 100%;
  transform: rotate(45deg) translateX(8px) rotate(-45deg);
  
  @include animate(bubble, 12s, infinite, linear);

  &--white {
    border: 1px solid #fff;
  }

  &--rotate-2 {
      transform: rotate(45deg) translateX(50px) rotate(-45deg);
    @include animate(bubble2, 16s, infinite, linear);
  }

  &--rotate-3 {
      transform: rotate(-45deg) translateX(20px) rotate(45deg);
    @include animate(bubble3, 16s, infinite, linear);
  }

  &--rotate-4 {
      transform: rotate(-45deg) translateX(80px) rotate(45deg);
    @include animate(bubble3, 16s, infinite, linear);
  }
}

@include keyframes(bubble) {
	0% {
	  transform: scale(1) rotate(0deg) translateX(8px) rotate(0deg);
	}
  50% {
    transform: scale(1.05) rotate(180deg) translateX(8px) rotate(-180deg);
  }
	100% {
		transform: scale(1) rotate(360deg) translateX(8px) rotate(-360deg);
  }
}


@include keyframes(bubble2) {
	0% {
	  transform: scale(1) rotate(0deg) translateX(50px) rotate(0deg);
	}
  50% {
    transform: scale(1.05) rotate(180deg) translateX(50px) rotate(-180deg);
  }
	100% {
		transform: scale(1) rotate(360deg) translateX(50px) rotate(-360deg);
  }
}


@include keyframes(bubble3) {
	0% {
	  transform: scale(1) rotate(0deg) translateX(20px) rotate(0deg);
	}
  50% {
    transform: scale(1.05) rotate(-180deg) translateX(20px) rotate(180deg);
  }
	100% {
		transform: scale(1) rotate(-360deg) translateX(20px) rotate(360deg);
  }
}


@include keyframes(bubble4) {
	0% {
	  transform: scale(1) rotate(0deg) translateX(80px) rotate(0deg);
	}
  50% {
    transform: scale(1.05) rotate(-180deg) translateX(80px) rotate(180deg);
  }
	100% {
		transform: scale(1) rotate(-360deg) translateX(80px) rotate(360deg);
  }
}
