$font-stack: 'ClearSans', Arial, sans-serif;


// 2020 Colors
$grey-0: #14191E;
$grey-1: #3C4146;
$grey-2: #64696E;
$grey-3: #8C9196;
$grey-4: #B4B9BE;
$grey-5: #DCE1E6;

$teal: #00aab4;
$body: $grey-1;
$black: $grey-0;
$red: #b90000;
$green: #00aab4;
$yellow: #ffc300;


$border-radius: 10px;
