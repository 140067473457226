/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue:#007bff;
  --indigo:#6610f2;
  --purple:#6f42c1;
  --pink:#e83e8c;
  --red:#dc3545;
  --orange:#fd7e14;
  --yellow:#ffc107;
  --green:#28a745;
  --teal:#20c997;
  --cyan:#17a2b8;
  --white:#fff;
  --gray:#6c757d;
  --gray-dark:#343a40;
  --primary:#007bff;
  --secondary:#6c757d;
  --success:#28a745;
  --info:#17a2b8;
  --warning:#ffc107;
  --danger:#dc3545;
  --light:#f8f9fa;
  --dark:#343a40;
  --breakpoint-xs:0;
  --breakpoint-sm:576px;
  --breakpoint-md:768px;
  --breakpoint-lg:992px;
  --breakpoint-xl:1200px;
  --font-family-sans-serif:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  --font-family-monospace:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace; }

*, ::after, ::before {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent; }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[data-original-title], abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b, strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

a:hover {
  color: #0056b3;
  text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button, input {
  overflow: visible; }

button, select {
  text-transform: none; }

[type=reset], [type=submit], button, html [type=button] {
  -webkit-appearance: button; }

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0; }

input[type=date], input[type=datetime-local], input[type=month], input[type=time] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto; }

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

.h1, h1 {
  font-size: 2.5rem; }

.h2, h2 {
  font-size: 2rem; }

.h3, h3 {
  font-size: 1.75rem; }

.h4, h4 {
  font-size: 1.5rem; }

.h5, h5 {
  font-size: 1.25rem; }

.h6, h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

.small, small {
  font-size: 80%;
  font-weight: 400; }

.mark, mark {
  padding: .2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }

.list-inline-item:not(:last-child) {
  margin-right: .5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }

.blockquote-footer::before {
  content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: .25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: .5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code, kbd, pre, samp {
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }

a > code {
  color: inherit; }

kbd {
  padding: .2rem .4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: .2rem; }

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gutters > .col, .no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0; }

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1; }

.order-last {
  -webkit-box-ordinal-group: 14;
  -ms-flex-order: 13;
  order: 13; }

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0; }

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1; }

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2; }

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3; }

.order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4; }

.order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5; }

.order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6; }

.order-7 {
  -webkit-box-ordinal-group: 8;
  -ms-flex-order: 7;
  order: 7; }

.order-8 {
  -webkit-box-ordinal-group: 9;
  -ms-flex-order: 8;
  order: 8; }

.order-9 {
  -webkit-box-ordinal-group: 10;
  -ms-flex-order: 9;
  order: 9; }

.order-10 {
  -webkit-box-ordinal-group: 11;
  -ms-flex-order: 10;
  order: 10; }

.order-11 {
  -webkit-box-ordinal-group: 12;
  -ms-flex-order: 11;
  order: 11; }

.order-12 {
  -webkit-box-ordinal-group: 13;
  -ms-flex-order: 12;
  order: 12; }

.offset-1 {
  margin-left: 8.333333%; }

.offset-2 {
  margin-left: 16.666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333333%; }

.offset-5 {
  margin-left: 41.666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333333%; }

.offset-8 {
  margin-left: 66.666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333333%; }

.offset-11 {
  margin-left: 91.666667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333333%; }
  .offset-sm-2 {
    margin-left: 16.666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333333%; }
  .offset-sm-5 {
    margin-left: 41.666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333333%; }
  .offset-sm-8 {
    margin-left: 66.666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333333%; }
  .offset-sm-11 {
    margin-left: 91.666667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333333%; }
  .offset-md-2 {
    margin-left: 16.666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333333%; }
  .offset-md-5 {
    margin-left: 41.666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333333%; }
  .offset-md-8 {
    margin-left: 66.666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333333%; }
  .offset-md-11 {
    margin-left: 91.666667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333333%; }
  .offset-lg-2 {
    margin-left: 16.666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333333%; }
  .offset-lg-5 {
    margin-left: 41.666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333333%; }
  .offset-lg-8 {
    margin-left: 66.666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333333%; }
  .offset-lg-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1; }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0; }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1; }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2; }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3; }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4; }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5; }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6; }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7; }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8; }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9; }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10; }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11; }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333333%; }
  .offset-xl-2 {
    margin-left: 16.666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333333%; }
  .offset-xl-5 {
    margin-left: 41.666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333333%; }
  .offset-xl-8 {
    margin-left: 66.666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333333%; }
  .offset-xl-11 {
    margin-left: 91.666667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }

.table td, .table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6; }

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6; }

.table tbody + tbody {
  border-top: 2px solid #dee2e6; }

.table .table {
  background-color: #fff; }

.table-sm td, .table-sm th {
  padding: .3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }

.table-bordered td, .table-bordered th {
  border: 1px solid #dee2e6; }

.table-bordered thead td, .table-bordered thead th {
  border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary, .table-primary > td, .table-primary > th {
  background-color: #b8daff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }

.table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
  background-color: #9fcdff; }

.table-secondary, .table-secondary > td, .table-secondary > th {
  background-color: #d6d8db; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }

.table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
  background-color: #c8cbcf; }

.table-success, .table-success > td, .table-success > th {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }

.table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color: #b1dfbb; }

.table-info, .table-info > td, .table-info > th {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }

.table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color: #abdde5; }

.table-warning, .table-warning > td, .table-warning > th {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }

.table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color: #ffe8a1; }

.table-danger, .table-danger > td, .table-danger > th {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }

.table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
  background-color: #f1b0b7; }

.table-light, .table-light > td, .table-light > th {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }

.table-hover .table-light:hover > td, .table-hover .table-light:hover > th {
  background-color: #ececf6; }

.table-dark, .table-dark > td, .table-dark > th {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }

.table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th {
  background-color: #b9bbbe; }

.table-active, .table-active > td, .table-active > th {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }

.table-dark td, .table-dark th, .table-dark thead th {
  border-color: #32383e; }

.table-dark.table-bordered {
  border: 0; }

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05); }

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-sm > .table-bordered {
    border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-md > .table-bordered {
    border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-lg > .table-bordered {
    border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-xl > .table-bordered {
    border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

.table-responsive > .table-bordered {
  border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

.form-control::-ms-expand {
  background-color: transparent;
  border: 0; }

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file, .form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: .375rem;
  padding-bottom: .375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }

.form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm, .input-group-lg > .form-control-plaintext.form-control, .input-group-lg > .input-group-append > .form-control-plaintext.btn, .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text, .input-group-lg > .input-group-prepend > .form-control-plaintext.btn, .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text, .input-group-sm > .form-control-plaintext.form-control, .input-group-sm > .input-group-append > .form-control-plaintext.btn, .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text, .input-group-sm > .input-group-prepend > .form-control-plaintext.btn, .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text {
  padding-right: 0;
  padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control, .input-group-sm > .input-group-append > .btn, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-prepend > .input-group-text {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem; }

.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]), .input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]), .input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]), .input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]), select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control, .input-group-lg > .input-group-append > .btn, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-prepend > .input-group-text {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem; }

.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]), .input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]), .input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]), .input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]), select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: .25rem; }

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }

.form-row > .col, .form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: .3rem;
  margin-left: -1.25rem; }

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: .75rem; }

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.custom-select.is-valid, .form-control.is-valid, .was-validated .custom-select:valid, .was-validated .form-control:valid {
  border-color: #28a745; }

.custom-select.is-valid:focus, .form-control.is-valid:focus, .was-validated .custom-select:valid:focus, .was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.custom-select.is-valid ~ .valid-feedback, .custom-select.is-valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .form-control.is-valid ~ .valid-tooltip, .was-validated .custom-select:valid ~ .valid-feedback, .was-validated .custom-select:valid ~ .valid-tooltip, .was-validated .form-control:valid ~ .valid-feedback, .was-validated .form-control:valid ~ .valid-tooltip {
  display: block; }

.form-check-input.is-valid ~ .form-check-label, .was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745; }

.form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip, .was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block; }

.custom-control-input.is-valid ~ .custom-control-label, .was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #28a745; }

.custom-control-input.is-valid ~ .custom-control-label::before, .was-validated .custom-control-input:valid ~ .custom-control-label::before {
  background-color: #71dd8a; }

.custom-control-input.is-valid ~ .valid-feedback, .custom-control-input.is-valid ~ .valid-tooltip, .was-validated .custom-control-input:valid ~ .valid-feedback, .was-validated .custom-control-input:valid ~ .valid-tooltip {
  display: block; }

.custom-control-input.is-valid:checked ~ .custom-control-label::before, .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.custom-control-input.is-valid:focus ~ .custom-control-label::before, .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.custom-file-input.is-valid ~ .custom-file-label, .was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #28a745; }

.custom-file-input.is-valid ~ .custom-file-label::before, .was-validated .custom-file-input:valid ~ .custom-file-label::before {
  border-color: inherit; }

.custom-file-input.is-valid ~ .valid-feedback, .custom-file-input.is-valid ~ .valid-tooltip, .was-validated .custom-file-input:valid ~ .valid-feedback, .was-validated .custom-file-input:valid ~ .valid-tooltip {
  display: block; }

.custom-file-input.is-valid:focus ~ .custom-file-label, .was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.custom-select.is-invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .was-validated .form-control:invalid {
  border-color: #dc3545; }

.custom-select.is-invalid:focus, .form-control.is-invalid:focus, .was-validated .custom-select:invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.custom-select.is-invalid ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-tooltip, .was-validated .custom-select:invalid ~ .invalid-feedback, .was-validated .custom-select:invalid ~ .invalid-tooltip, .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .form-control:invalid ~ .invalid-tooltip {
  display: block; }

.form-check-input.is-invalid ~ .form-check-label, .was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545; }

.form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip, .was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block; }

.custom-control-input.is-invalid ~ .custom-control-label, .was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #dc3545; }

.custom-control-input.is-invalid ~ .custom-control-label::before, .was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  background-color: #efa2a9; }

.custom-control-input.is-invalid ~ .invalid-feedback, .custom-control-input.is-invalid ~ .invalid-tooltip, .was-validated .custom-control-input:invalid ~ .invalid-feedback, .was-validated .custom-control-input:invalid ~ .invalid-tooltip {
  display: block; }

.custom-control-input.is-invalid:checked ~ .custom-control-label::before, .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.custom-control-input.is-invalid:focus ~ .custom-control-label::before, .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.custom-file-input.is-invalid ~ .custom-file-label, .was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #dc3545; }

.custom-file-input.is-invalid ~ .custom-file-label::before, .was-validated .custom-file-input:invalid ~ .custom-file-label::before {
  border-color: inherit; }

.custom-file-input.is-invalid ~ .invalid-feedback, .custom-file-input.is-invalid ~ .invalid-tooltip, .was-validated .custom-file-input:invalid ~ .invalid-feedback, .was-validated .custom-file-input:invalid ~ .invalid-tooltip {
  display: block; }

.custom-file-input.is-invalid:focus ~ .custom-file-label, .was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.form-inline .form-check {
  width: 100%; }

@media (min-width: 576px) {
  .form-inline label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0; }
  .form-inline .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0; }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .form-inline .form-control-plaintext {
    display: inline-block; }
  .form-inline .input-group {
    width: auto; }
  .form-inline .form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0; }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: .25rem;
    margin-left: 0; }
  .form-inline .custom-control {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .form-inline .custom-control-label {
    margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

.btn:focus, .btn:hover {
  text-decoration: none; }

.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.btn.disabled, .btn:disabled {
  opacity: .65; }

.btn:not(:disabled):not(.disabled) {
  cursor: pointer; }

.btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled):active {
  background-image: none; }

a.btn.disabled, fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc; }

.btn-primary.focus, .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf; }

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62; }

.btn-secondary.focus, .btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b; }

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34; }

.btn-success.focus, .btn-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430; }

.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b; }

.btn-info.focus, .btn-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f; }

.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00; }

.btn-warning.focus, .btn-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500; }

.btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130; }

.btn-danger.focus, .btn-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d; }

.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5; }

.btn-light.focus, .btn-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df; }

.btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124; }

.btn-dark.focus, .btn-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d; }

.btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-outline-primary.focus, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent; }

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-outline-secondary.focus, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent; }

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-outline-success.focus, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent; }

.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-outline-info.focus, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent; }

.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-outline-info:not(:disabled):not(.disabled).active:focus, .btn-outline-info:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-outline-warning.focus, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent; }

.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-outline-warning:not(:disabled):not(.disabled).active:focus, .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-outline-danger.focus, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent; }

.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-outline-light.focus, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent; }

.btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-outline-light:not(:disabled):not(.disabled).active:focus, .btn-outline-light:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-outline-dark.focus, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent; }

.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent; }

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent; }

.btn-link.focus, .btn-link:focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none; }

.btn-link.disabled, .btn-link:disabled {
  color: #6c757d; }

.btn-group-lg > .btn, .btn-lg {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem; }

.btn-group-sm > .btn, .btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem; }

.btn-block {
  display: block;
  width: 100%; }

.btn-block + .btn-block {
  margin-top: .5rem; }

input[type=button].btn-block, input[type=reset].btn-block, input[type=submit].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity .15s linear; }

.fade.show {
  opacity: 1; }

.collapse {
  display: none; }

.collapse.show {
  display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height .35s ease; }

.dropdown, .dropup {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: .25rem; }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: .125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: 0;
  border-right: .3em solid transparent;
  border-bottom: .3em solid;
  border-left: .3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: .125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-bottom: .3em solid transparent;
  border-left: .3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: .125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: .255em;
  vertical-align: .255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: .3em solid;
  border-bottom: .3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: .5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }

.dropdown-item:focus, .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa; }

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff; }

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: .5rem 1.5rem;
  margin-bottom: 0;
  font-size: .875rem;
  color: #6c757d;
  white-space: nowrap; }

.btn-group, .btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle; }

.btn-group-vertical > .btn, .btn-group > .btn {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto; }

.btn-group-vertical > .btn:hover, .btn-group > .btn:hover {
  z-index: 1; }

.btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus {
  z-index: 1; }

.btn-group .btn + .btn, .btn-group .btn + .btn-group, .btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group, .btn-group-vertical .btn + .btn, .btn-group-vertical .btn + .btn-group, .btn-group-vertical .btn-group + .btn, .btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px; }

.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.btn-toolbar .input-group {
  width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn-group:not(:last-child) > .btn, .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: .5625rem;
  padding-left: .5625rem; }

.dropdown-toggle-split::after {
  margin-left: 0; }

.btn-group-sm > .btn + .dropdown-toggle-split, .btn-sm + .dropdown-toggle-split {
  padding-right: .375rem;
  padding-left: .375rem; }

.btn-group-lg > .btn + .dropdown-toggle-split, .btn-lg + .dropdown-toggle-split {
  padding-right: .75rem;
  padding-left: .75rem; }

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.btn-group-vertical .btn, .btn-group-vertical .btn-group {
  width: 100%; }

.btn-group-vertical > .btn + .btn, .btn-group-vertical > .btn + .btn-group, .btn-group-vertical > .btn-group + .btn, .btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0; }

.btn-group-vertical > .btn-group:not(:last-child) > .btn, .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn-group:not(:first-child) > .btn, .btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }

.btn-group-toggle > .btn input[type=checkbox], .btn-group-toggle > .btn input[type=radio], .btn-group-toggle > .btn-group > .btn input[type=checkbox], .btn-group-toggle > .btn-group > .btn input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%; }

.input-group > .custom-file, .input-group > .custom-select, .input-group > .form-control {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0; }

.input-group > .custom-file:focus, .input-group > .custom-select:focus, .input-group > .form-control:focus {
  z-index: 3; }

.input-group > .custom-file + .custom-file, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .form-control, .input-group > .custom-select + .custom-file, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .form-control, .input-group > .form-control + .custom-file, .input-group > .form-control + .custom-select, .input-group > .form-control + .form-control {
  margin-left: -1px; }

.input-group > .custom-select:not(:last-child), .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .custom-select:not(:first-child), .input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .custom-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .custom-file:not(:first-child) .custom-file-label, .input-group > .custom-file:not(:first-child) .custom-file-label::before {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group-append, .input-group-prepend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.input-group-append .btn, .input-group-prepend .btn {
  position: relative;
  z-index: 2; }

.input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .btn, .input-group-append .input-group-text + .input-group-text, .input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem; }

.input-group-text input[type=checkbox], .input-group-text input[type=radio] {
  margin-top: 0; }

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child), .input-group > .input-group-append:not(:last-child) > .btn, .input-group > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child), .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #007bff; }

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff; }

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d; }

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef; }

.custom-control-label {
  margin-bottom: 0; }

.custom-control-label::before {
  position: absolute;
  top: .25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #dee2e6; }

.custom-control-label::after {
  position: absolute;
  top: .25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: .25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem 1.75rem .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5); }

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: .75rem;
  background-image: none; }

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef; }

.custom-select::-ms-expand {
  opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: .375rem;
  padding-bottom: .375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: .375rem;
  padding-bottom: .375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }

.custom-file-input:focus ~ .custom-file-control {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-file-input:focus ~ .custom-file-control::before {
  border-color: #80bdff; }

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem; }

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(calc(2.25rem + 2px) - 1px * 2);
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 .25rem .25rem 0; }

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: .5rem 1rem; }

.nav-link:focus, .nav-link:hover {
  text-decoration: none; }

.nav-link.disabled {
  color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }

.nav-tabs .nav-item {
  margin-bottom: -1px; }

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; }

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6; }

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent; }

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff; }

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: .25rem; }

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: .5rem 1rem; }

.navbar > .container, .navbar > .container-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }

.navbar-brand:focus, .navbar-brand:hover {
  text-decoration: none; }

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0; }

.navbar-nav .dropdown-menu {
  position: static;
  float: none; }

.navbar-text {
  display: inline-block;
  padding-top: .5rem;
  padding-bottom: .5rem; }

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .25rem; }

.navbar-toggler:focus, .navbar-toggler:hover {
  text-decoration: none; }

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-sm .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto; }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .navbar-expand-sm .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .navbar-expand-sm .navbar-toggler {
    display: none; }
  .navbar-expand-sm .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-md .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto; }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .navbar-expand-md .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .navbar-expand-md .navbar-toggler {
    display: none; }
  .navbar-expand-md .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto; }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .navbar-expand-lg .navbar-toggler {
    display: none; }
  .navbar-expand-lg .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-xl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto; }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .navbar-expand-xl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .navbar-expand-xl .navbar-toggler {
    display: none; }
  .navbar-expand-xl .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; } }

.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start; }

.navbar-expand > .container, .navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0; }

.navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row; }

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute; }

.navbar-expand .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto; }

.navbar-expand .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem; }

.navbar-expand > .container, .navbar-expand > .container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.navbar-expand .navbar-collapse {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto; }

.navbar-expand .navbar-toggler {
  display: none; }

.navbar-expand .dropup .dropdown-menu {
  top: auto;
  bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7); }

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }

.navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
  color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75); }

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show > .nav-link {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }

.navbar-dark .navbar-text a {
  color: #fff; }

.navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
  color: #fff; }

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: .25rem; }

.card > hr {
  margin-right: 0;
  margin-left: 0; }

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; }

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem; }

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: .75rem; }

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0; }

.card-header + .list-group .list-group-item:first-child {
  border-top: 0; }

.card-footer {
  padding: .75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

.card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px); }

.card-header-tabs {
  margin-right: -.625rem;
  margin-bottom: -.75rem;
  margin-left: -.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -.625rem;
  margin-left: -.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px); }

.card-deck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
  .card-deck .card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px; } }

.card-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column; }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap; }
  .card-group > .card {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0; }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0; }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .card-group > .card:first-child .card-header, .card-group > .card:first-child .card-img-top {
    border-top-right-radius: 0; }
  .card-group > .card:first-child .card-footer, .card-group > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0; }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .card-group > .card:last-child .card-header, .card-group > .card:last-child .card-img-top {
    border-top-left-radius: 0; }
  .card-group > .card:last-child .card-footer, .card-group > .card:last-child .card-img-bottom {
    border-bottom-left-radius: 0; }
  .card-group > .card:only-child {
    border-radius: .25rem; }
  .card-group > .card:only-child .card-header, .card-group > .card:only-child .card-img-top {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem; }
  .card-group > .card:only-child .card-footer, .card-group > .card:only-child .card-img-bottom {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem; }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0; }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top {
    border-radius: 0; } }

.card-columns .card {
  margin-bottom: .75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem; }
  .card-columns .card {
    display: inline-block;
    width: 100%; } }

.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: .75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: .25rem; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: .5rem;
  padding-left: .5rem;
  color: #6c757d;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: .25rem; }

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }

.page-link:hover {
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem; }

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem; }

.badge {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem; }

.badge:empty {
  display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }

.badge-primary[href]:focus, .badge-primary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc; }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }

.badge-secondary[href]:focus, .badge-secondary[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #545b62; }

.badge-success {
  color: #fff;
  background-color: #28a745; }

.badge-success[href]:focus, .badge-success[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }

.badge-info[href]:focus, .badge-info[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }

.badge-warning[href]:focus, .badge-warning[href]:hover {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00; }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }

.badge-danger[href]:focus, .badge-danger[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }

.badge-light[href]:focus, .badge-light[href]:hover {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }

.badge-dark[href]:focus, .badge-dark[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: .3rem; }

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: .75rem 1.25rem;
  color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }

.alert-primary hr {
  border-top-color: #9fcdff; }

.alert-primary .alert-link {
  color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }

.alert-secondary hr {
  border-top-color: #c8cbcf; }

.alert-secondary .alert-link {
  color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }

.alert-success hr {
  border-top-color: #b1dfbb; }

.alert-success .alert-link {
  color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }

.alert-info hr {
  border-top-color: #abdde5; }

.alert-info .alert-link {
  color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }

.alert-warning hr {
  border-top-color: #ffe8a1; }

.alert-warning .alert-link {
  color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }

.alert-danger hr {
  border-top-color: #f1b0b7; }

.alert-danger .alert-link {
  color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }

.alert-light hr {
  border-top-color: #ececf6; }

.alert-light .alert-link {
  color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }

.alert-dark hr {
  border-top-color: #b9bbbe; }

.alert-dark .alert-link {
  color: #040505; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: .75rem;
  background-color: #e9ecef;
  border-radius: .25rem; }

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #007bff;
  transition: width .6s ease; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start; }

.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }

.list-group-item-action:focus, .list-group-item-action:hover {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa; }

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }

.list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; }

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem; }

.list-group-item:focus, .list-group-item:hover {
  z-index: 1;
  text-decoration: none; }

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  background-color: #fff; }

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }

.list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff; }

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }

.list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf; }

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }

.list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb; }

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }

.list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5; }

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }

.list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1; }

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }

.list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7; }

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }

.list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6; }

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }

.list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe; }

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }

.close:focus, .close:hover {
  color: #000;
  text-decoration: none;
  opacity: .75; }

.close:not(:disabled):not(.disabled) {
  cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none; }

.modal.fade .modal-dialog {
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out,-webkit-transform .3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%); }

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0); }

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (.5rem * 2)); }

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }

.modal-backdrop.fade {
  opacity: 0; }

.modal-backdrop.show {
  opacity: .5; }

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem; }

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }

.modal-footer > :not(:first-child) {
  margin-left: .25rem; }

.modal-footer > :not(:last-child) {
  margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  opacity: 0; }

.tooltip.show {
  opacity: .9; }

.tooltip .arrow {
  position: absolute;
  display: block;
  width: .8rem;
  height: .4rem; }

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-tooltip-auto[x-placement^=top], .bs-tooltip-top {
  padding: .4rem 0; }

.bs-tooltip-auto[x-placement^=top] .arrow, .bs-tooltip-top .arrow {
  bottom: 0; }

.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
  top: 0;
  border-width: .4rem .4rem 0;
  border-top-color: #000; }

.bs-tooltip-auto[x-placement^=right], .bs-tooltip-right {
  padding: 0 .4rem; }

.bs-tooltip-auto[x-placement^=right] .arrow, .bs-tooltip-right .arrow {
  left: 0;
  width: .4rem;
  height: .8rem; }

.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
  right: 0;
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000; }

.bs-tooltip-auto[x-placement^=bottom], .bs-tooltip-bottom {
  padding: .4rem 0; }

.bs-tooltip-auto[x-placement^=bottom] .arrow, .bs-tooltip-bottom .arrow {
  top: 0; }

.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000; }

.bs-tooltip-auto[x-placement^=left], .bs-tooltip-left {
  padding: 0 .4rem; }

.bs-tooltip-auto[x-placement^=left] .arrow, .bs-tooltip-left .arrow {
  right: 0;
  width: .4rem;
  height: .8rem; }

.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before {
  left: 0;
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: .25rem .5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .3rem; }

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: .5rem;
  margin: 0 .3rem; }

.popover .arrow::after, .popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-popover-auto[x-placement^=top], .bs-popover-top {
  margin-bottom: .5rem; }

.bs-popover-auto[x-placement^=top] .arrow, .bs-popover-top .arrow {
  bottom: calc((.5rem + 1px) * -1); }

.bs-popover-auto[x-placement^=top] .arrow::after, .bs-popover-auto[x-placement^=top] .arrow::before, .bs-popover-top .arrow::after, .bs-popover-top .arrow::before {
  border-width: .5rem .5rem 0; }

.bs-popover-auto[x-placement^=top] .arrow::before, .bs-popover-top .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[x-placement^=top] .arrow::after, .bs-popover-top .arrow::after {
  bottom: 1px;
  border-top-color: #fff; }

.bs-popover-auto[x-placement^=right], .bs-popover-right {
  margin-left: .5rem; }

.bs-popover-auto[x-placement^=right] .arrow, .bs-popover-right .arrow {
  left: calc((.5rem + 1px) * -1);
  width: .5rem;
  height: 1rem;
  margin: .3rem 0; }

.bs-popover-auto[x-placement^=right] .arrow::after, .bs-popover-auto[x-placement^=right] .arrow::before, .bs-popover-right .arrow::after, .bs-popover-right .arrow::before {
  border-width: .5rem .5rem .5rem 0; }

.bs-popover-auto[x-placement^=right] .arrow::before, .bs-popover-right .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[x-placement^=right] .arrow::after, .bs-popover-right .arrow::after {
  left: 1px;
  border-right-color: #fff; }

.bs-popover-auto[x-placement^=bottom], .bs-popover-bottom {
  margin-top: .5rem; }

.bs-popover-auto[x-placement^=bottom] .arrow, .bs-popover-bottom .arrow {
  top: calc((.5rem + 1px) * -1); }

.bs-popover-auto[x-placement^=bottom] .arrow::after, .bs-popover-auto[x-placement^=bottom] .arrow::before, .bs-popover-bottom .arrow::after, .bs-popover-bottom .arrow::before {
  border-width: 0 .5rem .5rem .5rem; }

.bs-popover-auto[x-placement^=bottom] .arrow::before, .bs-popover-bottom .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[x-placement^=bottom] .arrow::after, .bs-popover-bottom .arrow::after {
  top: 1px;
  border-bottom-color: #fff; }

.bs-popover-auto[x-placement^=bottom] .popover-header::before, .bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7; }

.bs-popover-auto[x-placement^=left], .bs-popover-left {
  margin-right: .5rem; }

.bs-popover-auto[x-placement^=left] .arrow, .bs-popover-left .arrow {
  right: calc((.5rem + 1px) * -1);
  width: .5rem;
  height: 1rem;
  margin: .3rem 0; }

.bs-popover-auto[x-placement^=left] .arrow::after, .bs-popover-auto[x-placement^=left] .arrow::before, .bs-popover-left .arrow::after, .bs-popover-left .arrow::before {
  border-width: .5rem 0 .5rem .5rem; }

.bs-popover-auto[x-placement^=left] .arrow::before, .bs-popover-left .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[x-placement^=left] .arrow::after, .bs-popover-left .arrow::after {
  right: 1px;
  border-left-color: #fff; }

.popover-header {
  padding: .5rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px); }

.popover-header:empty {
  display: none; }

.popover-body {
  padding: .5rem .75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  transition: -webkit-transform .6s ease;
  transition: transform .6s ease;
  transition: transform .6s ease,-webkit-transform .6s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px; }

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: block; }

.carousel-item-next, .carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left, .carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
  transform: translateX(0); }

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left, .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.active.carousel-item-right, .carousel-item-next {
  -webkit-transform: translateX(100%);
  transform: translateX(100%); }

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  .active.carousel-item-right, .carousel-item-next {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); } }

.active.carousel-item-left, .carousel-item-prev {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%); }

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  .active.carousel-item-left, .carousel-item-prev {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); } }

.carousel-control-next, .carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5; }

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-next-icon, .carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }

.carousel-indicators li {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-color: rgba(255, 255, 255, 0.5); }

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: ""; }

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: ""; }

.carousel-indicators .active {
  background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:focus, a.bg-secondary:hover, button.bg-secondary:focus, button.bg-secondary:hover {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:focus, a.bg-success:hover, button.bg-success:focus, button.bg-success:hover {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:focus, a.bg-info:hover, button.bg-info:focus, button.bg-info:hover {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:focus, a.bg-warning:hover, button.bg-warning:focus, button.bg-warning:hover {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:focus, a.bg-danger:hover, button.bg-danger:focus, button.bg-danger:hover {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:focus, a.bg-light:hover, button.bg-light:focus, button.bg-light:hover {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:focus, a.bg-dark:hover, button.bg-dark:focus, button.bg-dark:hover {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }

.embed-responsive::before {
  display: block;
  content: ""; }

.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.857143%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important; }

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important; }

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important; }

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important; }

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important; }

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important; }

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important; }

.align-items-baseline {
  -webkit-box-align: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important; }

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important; }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: -webkit-sticky) or (position: sticky) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  -webkit-clip-path: none;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0, .my-0 {
  margin-top: 0 !important; }

.mr-0, .mx-0 {
  margin-right: 0 !important; }

.mb-0, .my-0 {
  margin-bottom: 0 !important; }

.ml-0, .mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1, .my-1 {
  margin-top: 0.25rem !important; }

.mr-1, .mx-1 {
  margin-right: 0.25rem !important; }

.mb-1, .my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1, .mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2, .my-2 {
  margin-top: 0.5rem !important; }

.mr-2, .mx-2 {
  margin-right: 0.5rem !important; }

.mb-2, .my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2, .mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3, .my-3 {
  margin-top: 1rem !important; }

.mr-3, .mx-3 {
  margin-right: 1rem !important; }

.mb-3, .my-3 {
  margin-bottom: 1rem !important; }

.ml-3, .mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4, .my-4 {
  margin-top: 1.5rem !important; }

.mr-4, .mx-4 {
  margin-right: 1.5rem !important; }

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4, .mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5, .my-5 {
  margin-top: 3rem !important; }

.mr-5, .mx-5 {
  margin-right: 3rem !important; }

.mb-5, .my-5 {
  margin-bottom: 3rem !important; }

.ml-5, .mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0, .py-0 {
  padding-top: 0 !important; }

.pr-0, .px-0 {
  padding-right: 0 !important; }

.pb-0, .py-0 {
  padding-bottom: 0 !important; }

.pl-0, .px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1, .py-1 {
  padding-top: 0.25rem !important; }

.pr-1, .px-1 {
  padding-right: 0.25rem !important; }

.pb-1, .py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1, .px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2, .py-2 {
  padding-top: 0.5rem !important; }

.pr-2, .px-2 {
  padding-right: 0.5rem !important; }

.pb-2, .py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2, .px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3, .py-3 {
  padding-top: 1rem !important; }

.pr-3, .px-3 {
  padding-right: 1rem !important; }

.pb-3, .py-3 {
  padding-bottom: 1rem !important; }

.pl-3, .px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4, .py-4 {
  padding-top: 1.5rem !important; }

.pr-4, .px-4 {
  padding-right: 1.5rem !important; }

.pb-4, .py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4, .px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5, .py-5 {
  padding-top: 3rem !important; }

.pr-5, .px-5 {
  padding-right: 3rem !important; }

.pb-5, .py-5 {
  padding-bottom: 3rem !important; }

.pl-5, .px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto, .my-auto {
  margin-top: auto !important; }

.mr-auto, .mx-auto {
  margin-right: auto !important; }

.mb-auto, .my-auto {
  margin-bottom: auto !important; }

.ml-auto, .mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1, .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1, .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1, .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1, .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2, .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2, .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2, .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2, .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3, .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3, .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3, .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4, .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4, .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4, .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4, .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5, .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5, .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5, .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5, .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1, .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1, .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1, .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1, .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2, .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2, .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2, .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2, .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3, .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3, .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3, .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3, .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4, .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4, .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4, .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4, .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5, .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5, .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5, .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5, .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0, .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1, .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1, .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1, .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1, .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2, .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2, .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2, .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2, .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3, .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3, .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3, .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3, .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4, .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4, .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4, .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4, .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5, .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5, .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5, .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5, .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0, .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0, .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0, .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1, .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1, .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1, .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1, .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2, .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2, .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2, .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2, .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3, .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3, .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3, .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3, .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4, .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4, .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4, .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4, .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5, .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5, .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5, .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5, .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto, .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1, .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1, .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1, .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1, .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2, .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2, .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2, .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2, .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3, .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3, .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3, .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4, .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4, .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4, .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4, .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5, .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5, .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5, .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5, .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1, .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1, .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1, .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1, .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2, .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2, .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2, .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2, .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3, .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3, .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3, .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3, .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4, .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4, .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4, .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4, .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5, .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5, .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5, .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5, .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1, .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1, .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1, .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1, .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2, .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2, .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2, .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2, .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3, .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3, .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3, .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3, .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4, .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4, .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4, .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4, .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5, .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5, .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5, .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5, .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1, .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1, .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1, .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1, .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2, .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2, .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2, .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2, .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3, .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3, .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3, .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3, .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4, .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4, .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4, .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4, .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5, .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5, .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5, .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5, .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #0062cc !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:focus, a.text-secondary:hover {
  color: #545b62 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:focus, a.text-success:hover {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:focus, a.text-info:hover {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:focus, a.text-warning:hover {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:focus, a.text-danger:hover {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:focus, a.text-light:hover {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:focus, a.text-dark:hover {
  color: #1d2124 !important; }

.text-muted {
  color: #6c757d !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *, ::after, ::before {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  blockquote, pre {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  img, tr {
    page-break-inside: avoid; }
  h2, h3, p {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
  .table td, .table th {
    background-color: #fff !important; }
  .table-bordered td, .table-bordered th {
    border: 1px solid #ddd !important; } }

/*# sourceMappingURL=bootstrap.min.css.map */
/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative; }

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden; }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item, .owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%; }

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none; }

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block; }

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.owl-height {
  transition: height .5s ease-in-out; }

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s ease; }

.owl-carousel .owl-item .owl-lazy:not([src]), .owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: 0; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform .1s ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  outline: none !important;
  overflow: visible; }

.hamburger:hover {
  opacity: 0.7; }

.hamburger.is-active:hover {
  opacity: 0.7; }

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #000; }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease; }

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block; }

.hamburger-inner::before {
  top: -10px; }

.hamburger-inner::after {
  bottom: -10px; }

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  perspective: 80px; }

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(180deg); }

.hamburger--3dx.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--3dx.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(-180deg); }

.hamburger--3dx-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--3dx-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  perspective: 80px; }

.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(-180deg); }

.hamburger--3dy.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--3dy.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg); }

.hamburger--3dy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--3dy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
  perspective: 80px; }

.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg); }

.hamburger--3dxy.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--3dxy.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg); }

.hamburger--3dxy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--3dxy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Turn
   */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg); }

.hamburger--arrowturn.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrowturn.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Turn Right
   */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg); }

.hamburger--arrowturn-r.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrowturn-r.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none; }

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg); }

.hamburger--boring.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0; }

.hamburger--boring.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg); }

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse-r .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }

.hamburger--collapse-r .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }

.hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic .hamburger-inner::before {
  top: 10px;
  transition: opacity 0.125s 0.275s ease; }

.hamburger--elastic .hamburger-inner::after {
  top: 20px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.075s; }

.hamburger--elastic.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0; }

.hamburger--elastic.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-270deg);
  transition-delay: 0.075s; }

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic-r .hamburger-inner::before {
  top: 10px;
  transition: opacity 0.125s 0.275s ease; }

.hamburger--elastic-r .hamburger-inner::after {
  top: 20px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-135deg);
  transition-delay: 0.075s; }

.hamburger--elastic-r.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0; }

.hamburger--elastic-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(270deg);
  transition-delay: 0.075s; }

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden; }

.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in; }

.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }

.hamburger--emphatic .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }

.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important; }

.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -80px;
  top: -80px;
  transform: translate3d(80px, 80px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -80px;
  top: -80px;
  transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden; }

.hamburger--emphatic-r .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in; }

.hamburger--emphatic-r .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }

.hamburger--emphatic-r .hamburger-inner::after {
  top: 10px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }

.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important; }

.hamburger--emphatic-r.is-active .hamburger-inner::before {
  left: -80px;
  top: 80px;
  transform: translate3d(80px, -80px, 0) rotate(-45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

.hamburger--emphatic-r.is-active .hamburger-inner::after {
  right: -80px;
  top: 80px;
  transform: translate3d(-80px, -80px, 0) rotate(45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear; }

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear; }

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0; }

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0; }

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 2px; }

.hamburger--slider .hamburger-inner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s; }

.hamburger--slider .hamburger-inner::after {
  top: 20px; }

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0; }

.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(-90deg); }

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 2px; }

.hamburger--slider-r .hamburger-inner::before {
  top: 10px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s; }

.hamburger--slider-r .hamburger-inner::after {
  top: 20px; }

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-45deg); }

.hamburger--slider-r.is-active .hamburger-inner::before {
  transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
  opacity: 0; }

.hamburger--slider-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -20px, 0) rotate(90deg); }

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }

.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }

.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }

.hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--spin-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }

.hamburger--spin-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.13s linear; }

.hamburger--spring .hamburger-inner::before {
  top: 10px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring .hamburger-inner::after {
  top: 20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important; }

.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(45deg); }

.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(-45deg); }

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }

.hamburger--spring-r .hamburger-inner::before {
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear; }

.hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear; }

.hamburger--stand .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear; }

.hamburger--stand.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--stand.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear; }

.hamburger--stand-r .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand-r .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear; }

.hamburger--stand-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--stand-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease; }

.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease; }

.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear; }

.hamburger--vortex .hamburger-inner::before {
  transition-property: top, opacity; }

.hamburger--vortex .hamburger-inner::after {
  transition-property: bottom, transform; }

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
  transition-delay: 0s; }

.hamburger--vortex.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0; }

.hamburger--vortex.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg); }

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear; }

.hamburger--vortex-r .hamburger-inner::before {
  transition-property: top, opacity; }

.hamburger--vortex-r .hamburger-inner::after {
  transition-property: bottom, transform; }

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
  transition-delay: 0s; }

.hamburger--vortex-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0; }

.hamburger--vortex-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg); }

@font-face {
  font-family: 'ClearSans';
  src: url("../fonts/ClearSans-Regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'ClearSans';
  src: url("../fonts/ClearSans-Italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'ClearSans';
  src: url("../fonts/ClearSans-Bold-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'ClearSans';
  src: url("../fonts/ClearSans-BoldItalic-webfont.woff") format("woff");
  font-weight: bold;
  font-style: italic; }

html {
  font-size: 62.5%; }

body {
  font-family: "ClearSans", Arial, sans-serif;
  font-size: 1.8rem;
  color: #3C4146; }

p {
  line-height: 1.5;
  margin-bottom: 2.5rem; }

p strong {
  font-size: 2.2rem;
  font-weight: 700;
  color: #000; }

h2 {
  margin: 0;
  padding: 0;
  color: #00aab4;
  font-size: 3rem;
  font-weight: bold;
  line-height: 1.2;
  position: relative;
  padding-bottom: 2.8rem;
  margin-bottom: 2.8rem;
  letter-spacing: -0.05em; }
  h2::after {
    content: "";
    display: block;
    width: 50px;
    height: 3px;
    background: #ffc300;
    bottom: 0;
    position: absolute; }

h3 {
  margin: 0;
  padding: 0;
  color: #14191E;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: -.05em;
  margin-bottom: 1.2rem;
  position: relative; }

.button {
  display: inline-block;
  letter-spacing: -0.04em;
  font-weight: bold;
  color: #00aab4;
  border: 1px solid #00aab4;
  border-radius: 10px;
  padding: 0.5em 1em;
  transition: all ease-in-out 0.3s;
  position: relative;
  transform: none;
  text-align: center; }
  @media (max-width: 767px) {
    .button {
      width: 100%; } }
  .button:hover {
    background: #00aab4;
    color: #fff;
    text-decoration: none;
    transform: translateY(-5px); }

::selection {
  background: #00aab4;
  color: #fff; }

.text-center {
  text-align: center; }
  .text-center::after {
    left: 50%;
    transform: translateX(-50%); }

html, body {
  overflow-x: hidden; }

body.iphone-fix {
  cursor: pointer; }

br.d-mobile {
  display: none; }
  @media (max-width: 500px) {
    br.d-mobile {
      display: block; } }

@keyframes rotate {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

@keyframes rotate--inverse {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(-359deg); } }

.main-navigation-section {
  width: 100%;
  display: block;
  padding: 2.8rem 0;
  position: fixed;
  top: 0;
  z-index: 20;
  transition: all ease 0.2s;
  background-color: #fff;
  box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.3); }
  @media (max-width: 991px) {
    .main-navigation-section {
      padding: 10px 0 0 0;
      position: absolute; } }

.main-navigation {
  width: 100%;
  max-width: 1170px;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media (max-width: 1199px) {
    .main-navigation {
      max-width: 990px; } }
  @media (max-width: 991px) {
    .main-navigation {
      max-width: 750px; } }
  .main-navigation__logo {
    width: 161px; }
    .main-navigation__logo img {
      max-width: 100%; }
    .main-navigation__logo--white {
      display: block; }
    .main-navigation__logo--color {
      display: none; }
    @media (max-width: 991px) {
      .main-navigation__logo {
        width: 120px; } }
  .main-navigation__menu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .main-navigation__menu-item {
      position: relative;
      padding: 0 1rem; }
      @media (max-width: 991px) {
        .main-navigation__menu-item {
          display: none; } }
      .main-navigation__menu-item--perspective {
        perspective: 300px; }
      .main-navigation__menu-item:hover > .main-navigation__menu-link {
        color: #000; }
      .main-navigation__menu-item:hover > .main-navigation__menu-link--button {
        color: #fff; }
      .main-navigation__menu-item:hover > .dropdown-wrapper {
        transform: rotateX(0);
        opacity: 1;
        display: block;
        height: 100%;
        left: -320px; }
      .main-navigation__menu-item--mobile {
        display: none;
        padding: 0; }
        @media (max-width: 991px) {
          .main-navigation__menu-item--mobile {
            display: block; } }
        .main-navigation__menu-item--mobile path {
          fill: #fff; }
        .main-navigation__menu-item--mobile svg {
          display: block; }
    .main-navigation__menu-link {
      color: #00aab4;
      font-weight: bold;
      display: block;
      padding: 0 0.8rem;
      position: relative;
      transition: ease all 0.2s;
      font-size: 1.6rem; }
      .main-navigation__menu-link:hover {
        color: #000;
        text-decoration: none; }
      .main-navigation__menu-link--mobile {
        padding: 1rem; }
        .main-navigation__menu-link--mobile path {
          fill: #3C4146; }
      .main-navigation__menu-link--button {
        background: #b90000;
        color: #fff;
        border-radius: 10px;
        padding: 1rem 2.4rem; }
        .main-navigation__menu-link--button:hover {
          color: #fff;
          background: #b90000; }

/* sticky nav */
body.scrolled .main-navigation-section {
  padding: 1.8rem 0;
  box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.3); }
  @media (max-width: 991px) {
    body.scrolled .main-navigation-section {
      padding: 10px 0 0; } }

body.scrolled .main-navigation__menu-link--mobile path {
  fill: #3C4146; }

body.scrolled .main-navigation__menu-item--mobile {
  top: -8px; }
  @media (max-width: 991px) {
    body.scrolled .main-navigation__menu-item--mobile {
      top: inherit; } }

body.scrolled .main-navigation__logo--white {
  display: none; }
  @media (max-width: 991px) {
    body.scrolled .main-navigation__logo--white {
      display: block; } }

body.scrolled .main-navigation__logo--color {
  display: block; }
  @media (max-width: 991px) {
    body.scrolled .main-navigation__logo--color {
      display: none; } }

/* mobile menu */
.main-navigation-mobile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 5;
  padding: 20px;
  height: 100%; }

.main-navigation-mobile-wrapper {
  position: absolute;
  z-index: 10;
  position: absolute;
  left: 0;
  top: 72px;
  right: 0;
  display: none; }
  @media (min-width: 992px) {
    .main-navigation-mobile-wrapper {
      display: none !important; } }

.main-navigation-mobile {
  background-color: #fff;
  position: relative;
  padding: 10px;
  box-shadow: 0px 13px 38px -6px rgba(0, 0, 0, 0.49);
  position: relative; }
  .main-navigation-mobile__close {
    position: absolute;
    top: 18px;
    right: 21px;
    width: 40px;
    height: 40px;
    z-index: 12;
    cursor: pointer;
    display: none; }
    .main-navigation-mobile__close-icon {
      display: block;
      width: 40px;
      position: absolute; }
      .main-navigation-mobile__close-icon path {
        stroke: #3C4146;
        fill: transparent;
        stroke-linecap: round;
        stroke-width: 5; }
  .main-navigation-mobile .dropdown-wrapper {
    transform: none;
    opacity: 1;
    width: 100%;
    left: inherit;
    padding: 0;
    position: relative; }
    .main-navigation-mobile .dropdown-wrapper::before {
      display: none; }
  .main-navigation-mobile .dropdown {
    border-radius: 0px;
    box-shadow: none;
    padding: 0; }
  .main-navigation-mobile .dropdown__item-icon {
    width: 60px; }
    .main-navigation-mobile .dropdown__item-icon img {
      max-width: 100%; }
  .main-navigation-mobile__solutions {
    padding-top: 0px; }
    @media (max-width: 991px) {
      .main-navigation-mobile__solutions {
        display: none; } }
    .main-navigation-mobile__solutions-headline {
      margin: 0; }
  .main-navigation-mobile__links h3 {
    margin: 0;
    font-size: 2rem; }
    .main-navigation-mobile__links h3 a {
      color: #3C4146;
      border-bottom: 1px solid #DCE1E6;
      display: block;
      padding: 2rem 1rem;
      text-decoration: none; }
    .main-navigation-mobile__links h3:active, .main-navigation-mobile__links h3:focus, .main-navigation-mobile__links h3:hover {
      text-decoration: none; }
  .main-navigation-mobile__links svg {
    position: absolute;
    right: 35px;
    transform: scale(1.2); }
  .main-navigation-mobile__request-demo {
    padding-top: 10px; }
    .main-navigation-mobile__request-demo a {
      text-align: center;
      padding: 1.4rem 2.4rem; }

.dropdown-wrapper {
  padding-top: 47px;
  position: absolute;
  width: 800px;
  left: 4000px;
  transform: rotateX(-15deg);
  transform-origin: 50% 0px;
  transition: left 0s, opacity 0.1s, transform 0.1s;
  opacity: 0; }
  .scrolled .dropdown-wrapper {
    padding-top: 20px; }
  .dropdown-wrapper::before {
    content: "";
    width: 23px;
    height: 20px;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 10px;
    right: 135px; }

.dropdown {
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  padding: 9px;
  box-shadow: 3px 3px 0px 0px rgba(0, 0, 0, 0.12); }
  .dropdown__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .dropdown__item {
    display: flex;
    flex-wrap: nowrap;
    padding: 15px;
    position: relative;
    border-radius: 10px;
    border-bottom: 3px solid #fff;
    flex: 0 0 49%; }
    @media (max-width: 599px) {
      .dropdown__item {
        flex: 0 0 100%; } }
    .dropdown__item:last-child::after {
      display: none; }
      @media (max-width: 767px) {
        .dropdown__item:last-child::after {
          display: block; } }
    .dropdown__item:hover {
      text-decoration: none;
      border-bottom: 3px solid #ffc300; }
      .dropdown__item:hover .dropdown__item-icon {
        filter: none; }
      .dropdown__item:hover .dropdown__item-name {
        color: #00aab4;
        transition: all ease 0.3s; }
      .dropdown__item:hover .dropdown__item-description {
        color: #64696E; }
      .dropdown__item:hover::after {
        display: none; }
    .dropdown__item-icon {
      text-align: right;
      padding-right: 10px;
      filter: grayscale(100%);
      transition: all ease 0.3s;
      width: 85px;
      margin-top: -10px; }
      .dropdown__item-icon img {
        max-width: 100%; }
    .dropdown__item-content {
      flex: 1; }
    .dropdown__item-name {
      font-size: 14px;
      margin: 0;
      color: #8C9196;
      font-weight: bold;
      margin-bottom: 4px; }
    .dropdown__item-description {
      font-size: 11px;
      margin: 0;
      color: #8C9196; }

.request-demo-section {
  padding: 7rem 0;
  position: relative;
  z-index: 10; }
  .request-demo-section--two {
    margin-top: -32rem;
    z-index: 5;
    position: relative; }
    @media (max-width: 767px) {
      .request-demo-section--two {
        margin-top: -29rem; } }

.request-demo {
  width: 100%;
  max-width: 1170px;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin: 0 auto; }
  @media (max-width: 1199px) {
    .request-demo {
      max-width: 990px; } }
  @media (max-width: 991px) {
    .request-demo {
      max-width: 750px; } }
  .request-demo__paragraph {
    text-align: center;
    font-size: 2.7rem;
    padding: 0 10rem;
    margin: 0 auto;
    width: 90%; }
    .request-demo-section--two .request-demo__paragraph {
      margin-bottom: 7rem; }
    .request-demo-section--two .request-demo__paragraph {
      color: #fff; }
    @media (max-width: 1199px) {
      .request-demo__paragraph {
        padding: 0; } }
    @media (max-width: 991px) {
      .request-demo__paragraph {
        font-size: 2.2rem; } }
    @media (max-width: 767px) {
      .request-demo__paragraph {
        font-size: 1.8rem;
        width: 100%; } }
  .request-demo__form-wrapper {
    background-color: #fff;
    border-radius: 10px;
    width: 75%;
    margin: 0 auto;
    padding: 4rem; }
    @media (max-width: 991px) {
      .request-demo__form-wrapper {
        width: 100%;
        padding-bottom: 0; } }
    @media (max-width: 767px) {
      .request-demo__form-wrapper {
        padding: 4rem 2rem;
        padding-bottom: 0; } }
    .request-demo__form-wrapper h3 {
      text-align: center;
      font-size: 3rem;
      margin: 0;
      padding: 0;
      margin-bottom: 4rem;
      letter-spacing: normal; }
      @media (max-width: 767px) {
        .request-demo__form-wrapper h3 {
          font-size: 2.6rem; } }
  .request-demo__form {
    width: 70%;
    margin: 0 auto; }
    @media (max-width: 991px) {
      .request-demo__form {
        width: 80%; } }
    @media (max-width: 767px) {
      .request-demo__form {
        width: 100%; } }
    .request-demo__form .hs-form {
      display: flex;
      width: 100%; }
      @media (max-width: 767px) {
        .request-demo__form .hs-form {
          flex-wrap: wrap; } }
      .request-demo__form .hs-form .hs-email {
        flex: 2; }
        @media (max-width: 767px) {
          .request-demo__form .hs-form .hs-email {
            flex: 0 0 100%; } }
        .request-demo__form .hs-form .hs-email > label {
          display: none; }
      .request-demo__form .hs-form .hs-error-msgs {
        list-style: none;
        padding: 0;
        margin: 0;
        color: red;
        padding-left: 20px;
        margin-top: 10px; }
      .request-demo__form .hs-form .hs-submit {
        flex: 1; }
      .request-demo__form .hs-form input[type="email"] {
        height: 5.6rem;
        border-radius: 10px 0 0 10px;
        border: none;
        width: 100%;
        font-size: 1.8rem;
        padding-left: 2rem;
        border-top: 1px solid #b90000;
        border-left: 1px solid #b90000;
        border-bottom: 1px solid #b90000;
        appearance: none; }
        @media (max-width: 767px) {
          .request-demo__form .hs-form input[type="email"] {
            border-right: 1px solid #b90000;
            border-radius: 10px;
            margin-bottom: 5px;
            text-align: center;
            padding: 0 1rem; } }
      .request-demo__form .hs-form input[type="submit"] {
        border-radius: 0 10px 10px 0;
        background: #b90000;
        color: #fff;
        border: none;
        cursor: pointer;
        font-size: 1.8rem;
        letter-spacing: -0.04em;
        height: 5.6rem;
        border-top: 1px solid #b90000;
        border-right: 1px solid #b90000;
        border-bottom: 1px solid #b90000;
        transition: ease background 0.5s;
        padding: 0 3rem;
        appearance: none; }
        @media (max-width: 767px) {
          .request-demo__form .hs-form input[type="submit"] {
            border-left: 1px solid #b90000;
            border-radius: 10px;
            width: 100%; } }
        .request-demo__form .hs-form input[type="submit"]:hover {
          background: #b90000; }

.hs-field-desc {
  font-size: 10px;
  position: absolute;
  z-index: 10;
  top: 5px;
  right: 10px;
  color: #B4B9BE; }

.hs_error_rollup {
  display: none !important; }

.footer {
  width: 100%;
  max-width: 1170px;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  border-top: 3px solid #DCE1E6;
  padding-top: 8rem;
  padding-bottom: 8rem;
  flex-wrap: wrap;
  font-size: 1.6rem; }
  @media (max-width: 1199px) {
    .footer {
      max-width: 990px; } }
  @media (max-width: 991px) {
    .footer {
      max-width: 750px; } }
  .footer__left {
    width: 57rem;
    display: flex; }
    @media (max-width: 991px) {
      .footer__left {
        width: 36rem; } }
    @media (max-width: 767px) {
      .footer__left {
        width: 100%;
        padding-bottom: 4rem; } }
  .footer__logo {
    flex: 0 0 96px; }
    .footer__logo img {
      display: block; }
  .footer__text p {
    margin: 0; }
    @media (max-width: 767px) {
      .footer__text p {
        font-size: 1.4rem; } }
  .footer__menu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .footer__menu-link {
      color: #3C4146;
      font-weight: bold;
      display: block;
      padding: 0 1.8rem;
      position: relative;
      transition: ease all 0.2s; }
      @media (max-width: 767px) {
        .footer__menu-link {
          padding: 0;
          padding-right: 3rem; } }
      .footer__menu-link:hover {
        color: #00aab4;
        text-decoration: none; }

.header-section {
  width: 100%;
  display: block;
  height: 540px;
  position: relative;
  background: none;
  background: linear-gradient(0deg, #0091a6 41%, #25c6b8 100%);
  overflow: hidden; }
  @media (max-width: 767px) {
    .header-section {
      height: auto;
      margin-bottom: 40px; } }
  .header-section--contact {
    height: 540px; }
    @media (max-width: 767px) {
      .header-section--contact {
        height: 480px; } }
  .header-section__curve {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 250px;
    fill: white;
    z-index: 2; }
  .header-section::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url("../img/header-overlay.png");
    background-repeat: no-repeat;
    background-position: left top;
    top: 0;
    background-size: cover; }

.header {
  width: 100%;
  max-width: 1170px;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin: 0 auto;
  padding-top: 21rem;
  position: relative; }
  @media (max-width: 1199px) {
    .header {
      max-width: 990px; } }
  @media (max-width: 991px) {
    .header {
      max-width: 750px; } }
  @media (max-width: 767px) {
    .header {
      padding-top: 10rem;
      padding-bottom: 0; } }
  .header::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url("../img/header-overlay-1.png");
    background-repeat: no-repeat;
    background-position: left top;
    top: 0;
    left: -500px; }
  .header__text {
    color: #fff;
    position: relative;
    z-index: 3; }
    @media (max-width: 767px) {
      .header__text {
        padding-top: 40px;
        padding-bottom: 80px; } }
  .header__headline {
    margin: 0;
    padding: 0;
    font-size: 3rem;
    font-weight: 700;
    line-height: 1;
    position: relative;
    margin-bottom: 2.5rem; }
    .header__headline--no-icon {
      margin-top: 48px; }
  .header__logo {
    margin-top: 76px;
    margin-bottom: 10px; }
  .header__paragraph {
    font-size: 3rem;
    line-height: 1.3;
    font-weight: 400; }
    @media (max-width: 767px) {
      .header__paragraph {
        font-size: 2rem; } }
    @media (max-width: 767px) {
      .header__paragraph br {
        display: none; } }
  .header__icon {
    width: 85px;
    margin-left: -20px; }

.card {
  padding: 3rem;
  background: #fff;
  border-radius: 10px;
  border: 1px #333;
  border-bottom: 3px solid #fff;
  transition: transform ease 1s; }
  @media (max-width: 767px) {
    .card {
      padding-left: 1rem;
      padding-right: 1rem; } }
  .card--active {
    border-bottom: 3px solid #ffc300; }
  .card:hover {
    transform: translateY(-5px);
    border-bottom: 3px solid #ffc300; }
  .card__content {
    text-align: center;
    margin: 0 auto;
    width: 90%; }
  .card__button {
    padding-right: 2.5em;
    padding-left: 2.5em; }
  .card__title {
    position: relative;
    padding-bottom: 1.2rem; }
    .card__title::after {
      content: "";
      display: block;
      width: 50px;
      height: 3px;
      background: #ffc300;
      bottom: 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%); }
  .card__description {
    font-size: 15px;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 10px; }

.form {
  padding-top: 20px;
  display: none; }
  .form .submitted-message {
    color: #ffc300;
    font-size: 15px; }
  .form .input {
    line-height: 1; }
  .form .hs-form-field {
    position: relative;
    margin-bottom: 20px;
    text-align: right; }
    .form .hs-form-field label {
      position: absolute;
      font-size: 15px;
      z-index: 2;
      background: #fff;
      padding: 0 4px;
      top: -10px;
      left: 10px; }
    .form .hs-form-field .hs-error-msgs {
      margin: 0;
      padding: 0;
      list-style: none;
      position: relative; }
      .form .hs-form-field .hs-error-msgs label {
        position: absolute;
        top: inherit;
        left: inherit;
        top: -8px;
        color: red;
        font-size: 11px;
        right: 5px; }
  .form input,
  .form textarea {
    border-radius: 10px;
    border: none;
    width: 100%;
    font-size: 1.8rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border: 1px solid #B4B9BE;
    position: relative;
    appearance: none !important; }
    .form input:focus,
    .form textarea:focus {
      outline: 0;
      border: 1px solid #ffc300; }
    .form input.invalid,
    .form textarea.invalid {
      border: 1px solid red; }
  .form input {
    height: 5.6rem; }
  .form textarea {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 5.6rem; }
  .form input[type="submit"] {
    background: #b90000;
    color: #fff;
    border-color: #b90000;
    cursor: pointer;
    transition: ease all 0.5s;
    appearance: none; }
    .form input[type="submit"]:hover {
      background: #860000;
      border-color: #860000;
      transform: translateY(-5px); }

.bubbles {
  position: absolute;
  z-index: 6;
  width: 1920px;
  height: 100%;
  top: 0;
  left: 50%;
  margin-left: -960px;
  display: none; }

.bubble {
  width: 100px;
  height: 100px;
  position: absolute;
  border-radius: 100%;
  transform: rotate(45deg) translateX(8px) rotate(-45deg);
  -webkit-animation: bubble 12s infinite linear;
  -moz-animation: bubble 12s infinite linear;
  -ms-animation: bubble 12s infinite linear;
  animation: bubble 12s infinite linear; }
  .bubble--white {
    border: 1px solid #fff; }
  .bubble--rotate-2 {
    transform: rotate(45deg) translateX(50px) rotate(-45deg);
    -webkit-animation: bubble2 16s infinite linear;
    -moz-animation: bubble2 16s infinite linear;
    -ms-animation: bubble2 16s infinite linear;
    animation: bubble2 16s infinite linear; }
  .bubble--rotate-3 {
    transform: rotate(-45deg) translateX(20px) rotate(45deg);
    -webkit-animation: bubble3 16s infinite linear;
    -moz-animation: bubble3 16s infinite linear;
    -ms-animation: bubble3 16s infinite linear;
    animation: bubble3 16s infinite linear; }
  .bubble--rotate-4 {
    transform: rotate(-45deg) translateX(80px) rotate(45deg);
    -webkit-animation: bubble3 16s infinite linear;
    -moz-animation: bubble3 16s infinite linear;
    -ms-animation: bubble3 16s infinite linear;
    animation: bubble3 16s infinite linear; }

@-webkit-keyframes bubble {
  0% {
    transform: scale(1) rotate(0deg) translateX(8px) rotate(0deg); }
  50% {
    transform: scale(1.05) rotate(180deg) translateX(8px) rotate(-180deg); }
  100% {
    transform: scale(1) rotate(360deg) translateX(8px) rotate(-360deg); } }

@-moz-keyframes bubble {
  0% {
    transform: scale(1) rotate(0deg) translateX(8px) rotate(0deg); }
  50% {
    transform: scale(1.05) rotate(180deg) translateX(8px) rotate(-180deg); }
  100% {
    transform: scale(1) rotate(360deg) translateX(8px) rotate(-360deg); } }

@-ms-keyframes bubble {
  0% {
    transform: scale(1) rotate(0deg) translateX(8px) rotate(0deg); }
  50% {
    transform: scale(1.05) rotate(180deg) translateX(8px) rotate(-180deg); }
  100% {
    transform: scale(1) rotate(360deg) translateX(8px) rotate(-360deg); } }

@keyframes bubble {
  0% {
    transform: scale(1) rotate(0deg) translateX(8px) rotate(0deg); }
  50% {
    transform: scale(1.05) rotate(180deg) translateX(8px) rotate(-180deg); }
  100% {
    transform: scale(1) rotate(360deg) translateX(8px) rotate(-360deg); } }

@-webkit-keyframes bubble2 {
  0% {
    transform: scale(1) rotate(0deg) translateX(50px) rotate(0deg); }
  50% {
    transform: scale(1.05) rotate(180deg) translateX(50px) rotate(-180deg); }
  100% {
    transform: scale(1) rotate(360deg) translateX(50px) rotate(-360deg); } }

@-moz-keyframes bubble2 {
  0% {
    transform: scale(1) rotate(0deg) translateX(50px) rotate(0deg); }
  50% {
    transform: scale(1.05) rotate(180deg) translateX(50px) rotate(-180deg); }
  100% {
    transform: scale(1) rotate(360deg) translateX(50px) rotate(-360deg); } }

@-ms-keyframes bubble2 {
  0% {
    transform: scale(1) rotate(0deg) translateX(50px) rotate(0deg); }
  50% {
    transform: scale(1.05) rotate(180deg) translateX(50px) rotate(-180deg); }
  100% {
    transform: scale(1) rotate(360deg) translateX(50px) rotate(-360deg); } }

@keyframes bubble2 {
  0% {
    transform: scale(1) rotate(0deg) translateX(50px) rotate(0deg); }
  50% {
    transform: scale(1.05) rotate(180deg) translateX(50px) rotate(-180deg); }
  100% {
    transform: scale(1) rotate(360deg) translateX(50px) rotate(-360deg); } }

@-webkit-keyframes bubble3 {
  0% {
    transform: scale(1) rotate(0deg) translateX(20px) rotate(0deg); }
  50% {
    transform: scale(1.05) rotate(-180deg) translateX(20px) rotate(180deg); }
  100% {
    transform: scale(1) rotate(-360deg) translateX(20px) rotate(360deg); } }

@-moz-keyframes bubble3 {
  0% {
    transform: scale(1) rotate(0deg) translateX(20px) rotate(0deg); }
  50% {
    transform: scale(1.05) rotate(-180deg) translateX(20px) rotate(180deg); }
  100% {
    transform: scale(1) rotate(-360deg) translateX(20px) rotate(360deg); } }

@-ms-keyframes bubble3 {
  0% {
    transform: scale(1) rotate(0deg) translateX(20px) rotate(0deg); }
  50% {
    transform: scale(1.05) rotate(-180deg) translateX(20px) rotate(180deg); }
  100% {
    transform: scale(1) rotate(-360deg) translateX(20px) rotate(360deg); } }

@keyframes bubble3 {
  0% {
    transform: scale(1) rotate(0deg) translateX(20px) rotate(0deg); }
  50% {
    transform: scale(1.05) rotate(-180deg) translateX(20px) rotate(180deg); }
  100% {
    transform: scale(1) rotate(-360deg) translateX(20px) rotate(360deg); } }

@-webkit-keyframes bubble4 {
  0% {
    transform: scale(1) rotate(0deg) translateX(80px) rotate(0deg); }
  50% {
    transform: scale(1.05) rotate(-180deg) translateX(80px) rotate(180deg); }
  100% {
    transform: scale(1) rotate(-360deg) translateX(80px) rotate(360deg); } }

@-moz-keyframes bubble4 {
  0% {
    transform: scale(1) rotate(0deg) translateX(80px) rotate(0deg); }
  50% {
    transform: scale(1.05) rotate(-180deg) translateX(80px) rotate(180deg); }
  100% {
    transform: scale(1) rotate(-360deg) translateX(80px) rotate(360deg); } }

@-ms-keyframes bubble4 {
  0% {
    transform: scale(1) rotate(0deg) translateX(80px) rotate(0deg); }
  50% {
    transform: scale(1.05) rotate(-180deg) translateX(80px) rotate(180deg); }
  100% {
    transform: scale(1) rotate(-360deg) translateX(80px) rotate(360deg); } }

@keyframes bubble4 {
  0% {
    transform: scale(1) rotate(0deg) translateX(80px) rotate(0deg); }
  50% {
    transform: scale(1.05) rotate(-180deg) translateX(80px) rotate(180deg); }
  100% {
    transform: scale(1) rotate(-360deg) translateX(80px) rotate(360deg); } }

.products-section {
  padding: 7rem 0 3rem;
  width: 100%;
  max-width: 1170px;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 1199px) {
    .products-section {
      max-width: 990px; } }
  @media (max-width: 991px) {
    .products-section {
      max-width: 750px; } }
  @media (max-width: 1199px) {
    .products-section {
      padding-bottom: 5rem; } }
  @media (max-width: 991px) {
    .products-section {
      flex-wrap: wrap; } }

.our-products {
  flex: 0 27.5rem;
  margin-top: 3rem; }
  @media (max-width: 991px) {
    .our-products {
      flex: 0 100%;
      margin-top: 0;
      margin-bottom: 3rem; } }
  .our-products__paragraph {
    font-size: 1.8rem; }
    .our-products__paragraph--lg {
      color: #14191E;
      font-size: 2.2rem;
      font-weight: bold; }

.products {
  flex: 1;
  padding-left: 7rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 70rem;
  align-content: flex-start; }
  @media (max-width: 1199px) {
    .products {
      padding-left: 4rem; } }
  @media (max-width: 991px) {
    .products {
      padding-left: 0; } }
  @media (max-width: 767px) {
    .products {
      height: auto; } }
  .products__item {
    display: flex;
    align-items: flex-start;
    width: calc(50% - 1.2rem);
    border-radius: 10px;
    padding: 2.5rem 1rem 2.5rem 2rem;
    margin: 0.6rem;
    margin-bottom: 0.6rem;
    margin-top: 0;
    border-bottom: 0px solid #fff;
    position: relative;
    transition: transform 0.2s, border 0.2s;
    transform: none;
    border-bottom: 3px solid #fff; }
    @media (max-width: 767px) {
      .products__item {
        width: calc(100% - 1.2rem); } }
    .products__item:hover {
      text-decoration: none;
      border-bottom: 3px solid #ffc300;
      transform: translateY(-5px);
      box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.05); }
  .products__icon {
    flex: 0 9rem;
    text-align: center;
    padding-right: 1rem; }
  .products__text {
    flex: 1; }
  .products__description {
    margin: 0;
    padding: 0;
    color: #3C4146;
    font-size: 1.6rem; }
    @media (max-width: 1199px) {
      .products__description {
        font-size: 1.4rem; } }

.c-square {
  position: absolute;
  bottom: 0;
  right: 0;
  bottom: 50px;
  width: 100%;
  fill: none;
  stroke: #2bc5c9;
  stroke-miterlimit: 10;
  stroke-width: 1;
  width: 60px;
  height: 60px;
  animation: linear 20s rotate infinite;
  right: 120px;
  z-index: 1; }
  .c-square--inverse {
    animation: linear 30s rotate--inverse infinite; }
  .c-square--2 {
    width: 30px;
    height: 30px;
    right: 0px;
    bottom: 200px; }
  .c-square--3 {
    width: 500px;
    height: 500px;
    right: 80px;
    bottom: -136px;
    z-index: 1;
    stroke-width: 0.01em;
    animation: none;
    transform: rotate(10deg);
    stroke: #27b0b4; }
  .c-square--4 {
    width: 30px;
    height: 30px;
    right: inherit;
    left: -50px;
    bottom: 40px; }
  .c-square--5 {
    right: inherit;
    left: 20px;
    bottom: -100px; }
  .c-square--6 {
    width: 200px;
    height: 200px;
    right: inherit;
    right: 30px;
    bottom: -10px; }

.c-btn {
  display: inline-block;
  background: #b90000;
  color: #fff;
  font-weight: 600;
  font-size: 1em;
  border-radius: 10px;
  padding: 0.6em 2.4em;
  transform: none;
  transition: all ease 0.25s; }
  .c-btn:hover {
    color: #fff;
    text-decoration: none;
    transform: translateY(-4px); }
  .c-btn--teal {
    background: #00aab4; }

.landing-intro {
  padding-top: 40px;
  width: 100%;
  max-width: 1170px;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin: 0 auto;
  text-align: center; }
  @media (max-width: 1199px) {
    .landing-intro {
      max-width: 990px; } }
  @media (max-width: 991px) {
    .landing-intro {
      max-width: 750px; } }
  .landing-intro p {
    font-size: 1.2em; }
  .landing-intro__headline::after {
    left: 50%;
    transform: translateX(-50%); }
  .landing-intro__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 100px; }
    .landing-intro__buttons .c-btn {
      margin: 0 20px; }
