.main-navigation-section {
  width: 100%;
  display: block;
  padding: 2.8rem 0;
  position: fixed;
  top: 0;
  z-index: 20;
  transition: all ease 0.2s;
  background-color: #fff;
  box-shadow: 0px 0px 0px 3px rgba(0,0,0,0.3);

  @include md {
    padding: 10px 0 0 0;
    position: absolute;
  }
}

.main-navigation {
  @include container;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__logo {
    width: 161px;

    img {
      max-width: 100%;
    }

    &--white {
      display: block;
    }

    &--color {
      display: none;
    }

    @include md {
      width: 120px;
    }
  }

  &__menu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-item {
      position: relative;
      padding: 0 1rem;

      @include md {
        display: none;
      }

      &--perspective {
        perspective: 300px;
      }

      &:hover > .main-navigation__menu-link {
        color: #000;
      }

      &:hover > .main-navigation__menu-link--button {
        color: #fff;
      }

      &:hover > .dropdown-wrapper{
        transform: rotateX(0);
        opacity: 1;
        display: block;
        height: 100%;
        left: -320px;
      }

      &--mobile {
        display: none;
        padding: 0;

        @include md {
          display: block;
        }

        path {
         fill: #fff;
        }

        svg {
          display: block;
        }
      }
    }

    &-link{
      color: $teal;
      font-weight: bold;
      display: block;
      padding: 0 0.8rem;
      position: relative;
      transition: ease all 0.2s;
      font-size: 1.6rem;

      &:hover {
        color: #000;
        text-decoration: none;
      }

      &--mobile {
        padding: 1rem;

        path {
          fill: $body;
        }
      }

      &--button {
				background: $red;
        color: #fff;
        border-radius: $border-radius;
        padding: 1rem 2.4rem;

        &:hover {
          color: #fff;
          background: $red;
        }
      }
    }
  }
}

/* sticky nav */
body.scrolled {
  .main-navigation-section {
    padding: 1.8rem 0;
    box-shadow: 0px 0px 0px 3px rgba(0,0,0,0.3);

    @include md {
      padding: 10px 0 0;
    }
  }

  .main-navigation__menu-link--mobile path{
    fill: $body;
  }

    .main-navigation__menu-item--mobile {
        top: -8px;

        @include md {
          top: inherit;
        }
    }

  .main-navigation__logo--white {
    display: none;

    @include md {
      display: block;
    }
  }
  .main-navigation__logo--color {
    display: block;

    @include md {
      display: none;
    }
  }
}


/* mobile menu */

.main-navigation-mobile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.8);
  z-index: 5;
  padding: 20px;
  height: 100%;
}

.main-navigation-mobile-wrapper {
  position: absolute;
  z-index: 10;
  position: absolute;
  left: 0;
  top: 72px;
  right: 0;
  display: none;

  @media (min-width: 992px) {
    display: none !important;
  }
}

.main-navigation-mobile {
  background-color: #fff;
  position: relative;
  padding: 10px;
  box-shadow: 0px 13px 38px -6px rgba(0,0,0,0.49);
  position: relative;


  &__close {
    position: absolute;
    top: 18px;
    right: 21px;
    width: 40px;
    height: 40px;
    z-index: 12;
    cursor: pointer;
    display: none;

    &-icon {
      display: block;
      width: 40px;
      position: absolute;

      path {
        stroke: $body;
        fill: transparent;
        stroke-linecap: round;
        stroke-width: 5;
      }
    }
  }

  & .dropdown-wrapper {
    transform: none;
    opacity: 1;
    width: 100%;
    left: inherit;
    padding: 0;
    position: relative;

    &::before {
      display: none;
    }
  }

  & .dropdown {
    border-radius: 0px;
    box-shadow: none;
    padding: 0;
  }

  & .dropdown__item-icon {
    width: 60px;

    img {
      max-width: 100%;
    }
   }

   &__solutions {
      padding-top: 0px;

      @include md {
        display: none;
      }

      &-headline {
        margin: 0;
      }
   }

   &__links {

     h3 {
       margin: 0;
       font-size: 2rem;


            a {
               color: $body;
               border-bottom: 1px solid $grey-5;
               display: block;
               padding: 2rem 1rem;
               text-decoration: none;
            }

            &:active,
            &:focus,
            &:hover {
              text-decoration: none;
            }
     }

     svg {
       position: absolute;
       right: 35px;
       transform: scale(1.2);
     }

   }

   &__request-demo {
     padding-top: 10px;

     a {
     text-align: center;
     padding: 1.4rem 2.4rem;
   }
 }
}
