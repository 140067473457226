.c-btn {
	display: inline-block;
	background: $red;
	color: #fff;
	font-weight: 600;
	font-size: 1em;
	border-radius: 10px;
 	padding: 0.6em 2.4em;
	transform: none;
	transition: all ease 0.25s;

	&:hover {
		color: #fff;
		text-decoration: none;
		transform: translateY(-4px);
	}

	&--teal {
		background: $teal;
	}
}
