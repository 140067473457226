.products-section {
  padding: 7rem 0 3rem;
  @include container;

  display: flex;
  justify-content: space-between;

  @include lg {
    padding-bottom: 5rem;
  }

  @include md {
    flex-wrap: wrap;
  }
}

.our-products {
  flex: 0 27.5rem;
  margin-top: 3rem;

  @include md {
    flex: 0 100%;
    margin-top: 0;
    margin-bottom: 3rem;
  }

  &__paragraph {
    font-size: 1.8rem;

    &--lg {
      color: $black;
      font-size: 2.2rem;
      font-weight: bold;
    }
  }
}

.products {
  flex: 1;
  padding-left: 7rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 70rem;
  align-content: flex-start;

  @include lg {
    padding-left: 4rem;
  }

  @include md {
    padding-left: 0;
  }

  @include sm {
    height: auto;
  }


  &__item {
    display: flex;
    align-items: flex-start;
    width: calc(50% - 1.2rem);
    border-radius: $border-radius;
    padding: 2.5rem 1rem 2.5rem 2rem;
    margin: 0.6rem;
    margin-bottom: 0.6rem;
    margin-top: 0;
    border-bottom: 0px solid #fff;
    position: relative;
    transition: transform 0.2s, border 0.2s;
    transform: none;
    border-bottom: 3px solid #fff;
    
    @include sm {
      width: calc(100% - 1.2rem);
    }

    &:hover {
      text-decoration: none;
      border-bottom: 3px solid $yellow;
      transform: translateY(-5px);
      box-shadow: 0px 0px 18px rgba(0,0,0,0.05);
    }
  }

  &__icon {
    flex: 0 9rem;
    text-align: center;
    padding-right: 1rem;
  }

  &__text {
    flex: 1;
  }

  &__description {
    margin: 0;
    padding: 0;
    color: $body;
    font-size: 1.6rem;

    @include lg {
      font-size: 1.4rem;
    }
  }
}
